
.footer{
    top:80%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
     position:absolute;

    
    
   }
 
.FooterNav {
    background-color: transparent;
    display: grid;
    align-self: center;
    grid-template-columns: 1fr 1fr 1fr;
    
   
}



  .BotonRed {
    
    justify-self: center;
    padding:0rem;
    
  }

  .iconoRed {
    height: 3em;
    width: 3em;
    margin:1rem;
    
  }