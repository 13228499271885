.backgroundMusica {
    background-color: #D39748;
    // height: 100vh;
    // min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 1.5rem 0 0rem 0;
}

.arte {
   width: 100%;
}



.player-wrapper {
    display: grid;
    grid-template-rows: auto;
    justify-content: center;
  }
  
  .react-player {
    height: 91% !important;
  }

  .cover{
    display: grid;
    justify-items: center;
}

.musicos{
    display: grid;
    // grid-template-rows: 1fr 1fr;
    padding: 2rem 0 2rem 0;
    row-gap: 3rem;
   
}



@media only screen and (min-width: 768px){

  .backgroundMusica {
    grid-template-columns: auto auto;
    gap: 4em;
    justify-content: center;

  

   .player-wrapper {
      max-width: 400px;
      .react-player {
        height: 100% !important; 
      }
      
   }

  
  

  .content {
    display: grid;
    justify-items: center;
    max-width: 500px;
    grid-template-rows: auto 1fr;
    gap: 2em;
  

    .cover {
      

   }
 
   .musicos {

     grid-template-columns: auto auto;
     gap: 4em;
     padding: 0em;

     .cardMain {  
       grid-template-rows: auto 1fr;
      .Lorenzo, .Eunice {
        justify-items: center;
        width: 100%;
        .fotoMusico {
          width: 83px;
        }
      }

      .descripcionMusico {
        width: 100%;
        padding: 0px;
        .estiloTitulo1, .estiloTitulo2 {
          font-size: 11px;
          
        } 
        .descripcion {
          font-size: 10px;
        }
      }
     }

   }
    
  }
  }
}