.header{
  top:0;
  position:sticky;
  z-index: 2;
}
.navigation {
    background-color: #0A1828;
    padding: 1em;
    display: grid;
    align-self: center;
    top:0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.item {
    display: block;
    padding: 0.5rem 1rem;
    justify-self: center;
    color:white;
    font-family: 'Gill Sans';
    font-size: 1.2em;
    text-decoration: none;
    

}

.item.active {
    color: white;
    text-decoration: underline;
    text-decoration-color: #AC1F8E;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }

  .item:hover {
    color: white;
    text-decoration: underline;
    text-decoration-color: #AC1F8E;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }


  .item2 {
    display: block;
    padding: 0.5rem 1rem;
    justify-self: center;
    color:white;
    font-family: 'Gill Sans';
    font-size: 1.2em;
    text-decoration: none;
    

}

.item2.active {
    color: white;
    text-decoration: underline;
    text-decoration-color: #8DC411;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }

  .item2:hover {
    color: white;
    text-decoration: underline;
    text-decoration-color: #8DC411;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }

  .item3 {
    display: block;
    padding: 0.5rem 1rem;
    justify-self: center;
    color:white;
    font-family: 'Gill Sans';
    font-size: 1.2em;
    text-decoration: none;
    

}

.item3.active {
    color: white;
    text-decoration: underline;
    text-decoration-color: #2EA7BB;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }

  .item3:hover {
    color: white;
    text-decoration: underline;
    text-decoration-color: #2EA7BB;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }

  .item4 {
    display: block;
    padding: 0.5rem 1rem;
    justify-self: center;
    color:white;
    font-family: 'Gill Sans';
    font-size: 1.2em;
    text-decoration: none;
    

}

.item4.active {
    color: white;
    text-decoration: underline;
    text-decoration-color: #ED8421;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }

  .item4:hover {
    color: white;
    text-decoration: underline;
    text-decoration-color: #ED8421;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    font-size: 1.2em;
  }