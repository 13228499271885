.cardEvento {
    grid-template-rows: auto auto;
    display: grid;
    background-color: #0A1828;
    border-radius: 15px;
    justify-content: center;
    padding: 30px 15px 7px 15px;
}

.datosEvento {
    grid-template-rows: auto auto auto auto auto auto;
    display: grid;
    justify-items: left;
    grid-gap: 10px;
}

.nombreEvento {
    font-family: "Gill Sans";
    color: white;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
}

.datosEventoItem {
    color: white;
    margin:0;
    font-size: 2.5vh;
}

.precioEvento {
    font-family: "Gill Sans";
    color: white;
    font-size: 2.5vh;
    font-weight: bold;
    justify-self: center;
}

.boletosControl {
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
}

.imagen{
    width: 18rem;
    justify-self: center;
    
}



.descripcionEvento {
    font-family: 'Gill Sans';
    color: white;
    font-size: 1rem;
    text-align: center;
}

.contador{
    font-family: "Gill Sans";
    color: #E20C8C;
    font-size: 3vh;
    text-align: center;
    margin: 0;
}