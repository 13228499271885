.RScontainer {

    cursor: pointer;
    color: white;
    border: 1.5px solid white;
    border-radius: 50px;
    background-color: transparent;
    font-family: "Gill Sans";
    font-size: 25px;
    align-self: center;
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr;
    padding: 5px 15px 5px 5px;
    // grid-gap: 10px;
  
}

.logoRS {
    width: 100%;
    max-width: 40px;
}