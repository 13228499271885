.cardMusico{
    background: linear-gradient(
    to right, 
    #ffffff 0%, 
    #ffffff 50%, 
    #000000 50%, 
    #000000 100%
  );
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.Eunice {
    background: #5B2738;
    width: 90%;
    justify-self: center;
    border-radius: 30px 30px 0 0;
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    padding: 1.5rem 0 0 0;
}

.Lorenzo {
    background:#0F283D;
    width: 90%;
    justify-self: center;
    border-radius: 30px 30px 0 0;
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    padding: 1.5rem 0 0 0;
}

.fotoMusico{
    width: 220px;
    border: 2px solid white;
}

.nombreMusico {
  font-family: "Gill Sans";
    color: #ffffff;
    margin: 0;
    padding: 1rem 0;
    font-size: 1.2rem;
}

.descripcionMusico{
    background:#F6F2E2;
    width: 90%;
    justify-self: center;
    border-radius: 0 0 30px 30px ;
    display: grid;
    grid-template-rows: min-content;
    justify-content: center;
    padding: 1.3rem 0 0 0;
    
}

.estiloTitulo1 {
    font-family: "Gill Sans";
    color: #871846;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 1rem;
}

.estiloTitulo2 {
  font-family: "Gill Sans";
  color: #144A75;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 1rem;
}

.descripcion {
  font-family: "Gill Sans";
    color: #3F1C23;
    font-size: 1rem;
    margin: 0;
    padding: 0 1rem 1rem;
    white-space: pre-wrap;
}


.cardMain {
  display:grid;

}