.backgroundContacto {
    background-image: linear-gradient(to right, #0A192A,#385170,#0A192A);
    min-height: 100vh;
    
}

.panel {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 35px;
    justify-content: center;
    padding: 10% 0px 10% 0px;
}

.ActionCall {
    font-family: "Gill Sans";
    color: white;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
    margin:0;
}