.backgroundInventarioItem {
    background-color: black;
    grid-template-rows: auto;
    justify-content: center;
    display:grid;
    padding: 1rem;
}

.imagen{
    width: 15rem;
    justify-self: center;
}

.itemNombre {
    font-family: 'Gill Sans';
    color: white;
    font-size: 2rem;
    text-align: center;
    margin: 0;
}