.backgroundEventosValidacion {
    background-image: linear-gradient(to right, #0A192A, #385170, #0A192A);
    min-height: 100vh;
    padding: 5%;
    display: grid;
    justify-content: center;
}

.cardValidacion {
    grid-template-rows: auto;
    display: grid;
    background-color: #0A1828;
    border-radius: 15px;
    /* justify-content: center; */
    padding: 25px 30px 26px 30px;
    gap: 7px;
    align-self: baseline;
    justify-items: center;

    
   
    
}