.backgroundAgregarFans {
    background-image: linear-gradient(to right, #0A192A, #385170, #0A192A);
    min-height: 100vh;
    padding: 5%;
    display: grid;
    justify-content: center;
}

.cardAgregarFans {
    grid-template-rows: 1fr 1fr 1fr;
    display: grid;
    background-color: #0A1828;
    border-radius: 15px;
    justify-content: center;
    padding: 25px 30px 26px 30px;
    gap: 25px;
    align-self: baseline;
}



.inputAgregarFans {
    color: darkslategray;
    border-radius: 25px;
    border-color: transparent;
    background-color: white;
    font-family: "Gill Sans";
    font-size: 3vh;
    padding: 0.5rem;
    max-height: 3rem;
}

.labelAgregarFans {
    font-family: "Gill Sans";
    color: white;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
    margin:0;
}

.labelerrorAgregarFans {
    font-family: "Gill Sans";
    color: white;
    text-align: center;
    font-size: 3vh;
    margin:0;
}