.backgroundHome {
    background-image: linear-gradient(to right, #0A192A,#385170,#0A192A);
    height: 100vh;
    min-height: 100%;
}


.logo{
    height: auto;
    width: 40rem;
    max-width: 90%;
    display: block;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top:30%;
    position: relative;
    z-index: 0;
  
}

