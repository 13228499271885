.mainButton{
        color: #E20C8C;
    border-radius: 25px;
    background-color: transparent;
    font-family: "Gill Sans";
    font-size: 4vh;
    padding: 0.7rem;
    border: 2px solid #E20C8C;
    cursor: pointer;
    max-height: 5rem;
    

}